import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import ReactSelect from "react-select";

const ConditionLine = (props) => {
  const {
    handleStepLogic,
    selectedConditionPosition,
    conditionIndex,
    logicIndex,
    operation,
    currentStep,
    t,
    value,
    conditionType,
    contacts,
    sectionId,
    score,
  } = props;

  const [multiSelectValue, setMultiSelectValue] = useState([]);
  const [multiSelectValueContact, setMultiSelectContactValue] = useState([]);
  const [matrixValue, setMatrixValue] = useState([]);
  const [multiCollapse, setMultiCollapse] = useState(false);
  const [isDateField, setIsDateField] = useState(false);
  const [isSelectCotact, setIsSelectCotact] = useState(false);
  const [isSignatureCotact, setIsSignatureCotact] = useState(false);
  const [isMultiSelect, setIsMultiSelect] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isInit, setIsInit] = useState(true);
  const [isSliderField, setIsSliderField] = useState(false);

  useEffect(() => {
    const multiOptions = ["one_of", "none_of", "all_of", "exact"].includes(
      operation
    );
    if (conditionType === "multiSelect" && value) {
      setMultiSelectValue(JSON.parse(value));
    } else if (
      conditionType === "Form Answers" &&
      isSelectCotact &&
      multiOptions &&
      value
    ) {
      setMultiSelectContactValue(JSON.parse(value));
    } else if (conditionType === "Form Answers" && !multiOptions && value) {
      setMultiSelectContactValue(value);
    } else if (conditionType === "singleSelect" && Array.isArray(value)) {
      setMultiSelectValue(JSON.parse(value));
    } else if (conditionType === "singleSelect" && !Array.isArray(value)) {
      setMultiSelectValue(value);
    } else if (conditionType === "Matrix" && Array.isArray(value)) {
      setMatrixValue(JSON.parse(value));
    } else if (conditionType === "Matrix" && !Array.isArray(value)) {
      setMatrixValue(value);
    }
  }, [value, isInit]);

  useEffect(() => {
    if (conditionType == "UTM") {
      const isOption = ["==", "!="].includes(operation);
      handleStepLogic(
        { target: { name: "operation", value: isOption ? operation : "==" } },
        selectedConditionPosition,
        conditionIndex,
        logicIndex
      );
    } else if (conditionType === "singleSelect") {
      const isOption = ["one_of", "none_of", "==", "!="].includes(operation);
      handleStepLogic(
        { target: { name: "operation", value: isOption ? operation : "==" } },
        selectedConditionPosition,
        conditionIndex,
        logicIndex
      );
    } else if (conditionType == "multiSelect") {
      const isOption = ["one_of", "none_of", "all_of", "exact"].includes(
        operation
      );
      handleStepLogic(
        {
          target: { name: "operation", value: isOption ? operation : "one_of" },
        },
        selectedConditionPosition,
        conditionIndex,
        logicIndex
      );
    } else if (
      conditionType == "rating" ||
      conditionType == "System" ||
      conditionType == "slider"
    ) {
      const isOption = ["<", ">"].includes(operation);
      handleStepLogic(
        { target: { name: "operation", value: isOption ? operation : "<" } },
        selectedConditionPosition,
        conditionIndex,
        logicIndex
      );
    }
    if (conditionType === "Form Answers") {
      const selectedField = Object.values(contacts).find(
        (item) => item.label === sectionId
      );
      setSelectedContact(selectedField);
      if (selectedField) {
        setIsDateField(selectedField.type === "date");
        setIsSliderField(selectedField.type === "slider");
        setIsSelectCotact(selectedField.type === "select");
        setIsSignatureCotact(selectedField.type === "signature");
        setIsMultiSelect(selectedField.isMultiSelect);
        let defaultOperation = "==";
        if (selectedField.type === "date" || selectedField.type === "slider")
          defaultOperation = "<";
        else if (selectedField.type === "select" && selectedField.isMultiSelect)
          defaultOperation = "one_of";
        handleStepLogic(
          {
            target: { name: "operation", value: operation || defaultOperation },
          },
          selectedConditionPosition,
          conditionIndex,
          logicIndex
        );
      } else {
        setIsSelectCotact(false);
        setIsMultiSelect(false);
        setIsSignatureCotact(false);
      }
    } else {
      setIsMultiSelect(false);
      setIsDateField(false);
      setIsSelectCotact(false);
      setIsSignatureCotact(false);
      setIsSliderField(false);
    }
    setIsInit(false);
  }, [sectionId]);

  const handleMultiSelectValue = (multiSelectValue) => {
    let valueToSave;
    if (Array.isArray(multiSelectValue)) {
      let multiSelectValueTemp = [...multiSelectValue];
      multiSelectValueTemp = multiSelectValue.map((option) => option.value);
      valueToSave = JSON.stringify(multiSelectValueTemp);
    } else {
      valueToSave = multiSelectValue.value;
    }

    handleStepLogic(
      {
        target: { name: "value", value: valueToSave },
      },
      selectedConditionPosition,
      conditionIndex,
      logicIndex
    );
    setMultiSelectValue(valueToSave);
  };
  const handleMultiSelectMatrix = (multiSelectValue) => {
    let valueToSave;
    if (Array.isArray(multiSelectValue)) {
      let multiSelectValueTemp = [...multiSelectValue];
      multiSelectValueTemp = multiSelectValue.map((option) => option.value);
      valueToSave = JSON.stringify(multiSelectValueTemp);
    } else {
      valueToSave = multiSelectValue.value;
    }

    handleStepLogic(
      {
        target: { name: "value", value: valueToSave },
      },
      selectedConditionPosition,
      conditionIndex,
      logicIndex
    );
    setMatrixValue(valueToSave);
  };

  const handleMultiSelectContactValue = (multiSelectValue) => {
    let valueToSave;
    if (Array.isArray(multiSelectValue)) {
      //Multi select
      let multiSelectValueTemp = [...multiSelectValue];
      multiSelectValueTemp = multiSelectValue.map((option) => option.value);
      valueToSave = JSON.stringify(multiSelectValueTemp);
    } else {
      //Single select
      valueToSave = multiSelectValue.value;
    }

    handleStepLogic(
      {
        target: { name: "value", value: valueToSave },
      },
      selectedConditionPosition,
      conditionIndex,
      logicIndex
    );
    setMultiSelectContactValue(valueToSave);
  };
  return (
    <>
      <div className="col-4">
        <select
          name="operation"
          className="form-control product-logic-input"
          value={operation}
          onChange={(e) => {
            handleStepLogic(
              e,
              selectedConditionPosition,
              conditionIndex,
              logicIndex
            );
            handleStepLogic(
              { target: { name: "value", value: "" } },
              selectedConditionPosition,
              conditionIndex,
              logicIndex
            );
            setMultiSelectValue([]);
            setMultiSelectContactValue([]);
          }}
        >
          {conditionType === "singleSelect" ||
          conditionType == "Matrix" ||
          conditionType == "UTM" ||
          conditionType == "Variables" ||
          (conditionType == "Form Answers" &&
            !isDateField &&
            !isSelectCotact &&
            !isSignatureCotact &&
            !isSliderField) ||
          (conditionType == "Form Answers" &&
            !isSignatureCotact &&
            !isDateField &&
            isSelectCotact &&
            !isMultiSelect &&
            !isSliderField) ? (
            <>
              <option value="==">
                {t("leftBar-welcome-logicModal-equal-to")}
              </option>
              <option value="!=">
                {t("leftBar-welcome-logicModal-not-equal-to")}
              </option>
              {conditionType == "singleSelect" ||
              conditionType == "Matrix" ||
              (conditionType == "Form Answers" &&
                !isSignatureCotact &&
                !isDateField &&
                isSelectCotact &&
                !isMultiSelect &&
                !isSliderField) ? (
                <>
                  <option value="one_of">
                    {t("leftBar-welcome-logicModal-equal-to-one-of-these")}
                  </option>
                  <option value="none_of">
                    {t("leftBar-welcome-logicModal-not-equal-to-one-of-these")}
                  </option>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {conditionType === "multiSelect" ||
          (conditionType == "Form Answers" &&
            isSelectCotact &&
            isMultiSelect) ? (
            <>
              {/* <option value="in">Includes</option>
            <option value="not in">Not includes</option> */}
              <option value="one_of">One of these</option>
              <option value="all_of">All of these</option>
              <option value="none_of">None of these</option>
              <option value="exact">Exact</option>
            </>
          ) : (
            <></>
          )}
          {isDateField ||
          isSliderField ||
          conditionType == "rating" ||
          conditionType == "slider" ||
          conditionType == "System" ? (
            <>
              <option value="<">
                {conditionType == "rating" ||
                conditionType == "System" ||
                conditionType == "slider" ||
                isSliderField
                  ? "GT"
                  : "After"}
              </option>
              <option value=">">
                {conditionType == "rating" ||
                conditionType == "System" ||
                conditionType == "slider" ||
                isSliderField
                  ? "LT"
                  : "Before"}
              </option>
            </>
          ) : (
            <></>
          )}

          {conditionType == "Form Answers" && isSignatureCotact ? (
            <>
              <option value="signed">Signed</option>
              <option value="not_signed">Isn't signed</option>
            </>
          ) : (
            <></>
          )}
        </select>
      </div>
      {conditionType === "multiSelect" || conditionType === "singleSelect" ? (
        <>
          <div
            className="col-4"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "18px",
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            <ReactSelect
              isMulti={["one_of", "none_of", "all_of", "exact"].includes(
                operation
              )}
              placeholder={"Select..."}
              menuShouldScrollIntoView={false}
              isSearchable={true}
              name="value"
              value={
                currentStep.options &&
                currentStep.options
                  .filter((option) => multiSelectValue.includes(option.id))
                  .map((option) => {
                    return {
                      label: option.title.replace(/<\/?[^>]+(>|$)/g, ""),
                      value: option.id,
                    };
                  })
              }
              options={
                currentStep.options &&
                currentStep.options
                  .filter((option) => !option.hide)
                  .map((option) => {
                    return {
                      label: option.title.replace(/<\/?[^>]+(>|$)/g, ""),
                      value: option.id,
                    };
                  })
              }
              onChange={handleMultiSelectValue}
              className="product-logic-input"
              styles={{
                control: (base) => ({
                  ...base,
                  marginRight: "-19px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }),
              }}
            />
          </div>
        </>
      ) : (
        <> </>
      )}
      {conditionType === "Matrix" ? (
        <>
          <div
            className="col-4"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "18px",
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            <ReactSelect
              isMulti={["one_of", "none_of", "all_of", "exact"].includes(
                operation
              )}
              placeholder={"Select..."}
              isSearchable={true}
              name="value"
              className="product-logic-input"
              value={
                currentStep.matrix &&
                currentStep.matrix.columns
                  .filter((column) => matrixValue.includes(column.id))
                  .map((column) => {
                    return {
                      label: column.value.replace(/<\/?[^>]+(>|$)/g, ""),
                      value: column.id,
                    };
                  })
              }
              options={
                currentStep.matrix &&
                currentStep.matrix.columns.map((column) => {
                  return {
                    label: column.value.replace(/<\/?[^>]+(>|$)/g, ""),
                    value: column.id,
                  };
                })
              }
              onChange={handleMultiSelectMatrix}
              styles={{
                control: (base) => ({
                  ...base,
                  marginRight: "-19px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }),
              }}
            />
          </div>
        </>
      ) : (
        <> </>
      )}

      {conditionType === "UTM" ||
      conditionType === "System" ||
      conditionType === "rating" ||
      conditionType === "slider" ||
      conditionType === "Variables" ||
      isSliderField ||
      (conditionType == "Form Answers" &&
        !isDateField &&
        !isSelectCotact &&
        !isSignatureCotact) ? (
        <>
          {" "}
          <div className="col-4">
            <input
              type={
                conditionType === "rating" ||
                conditionType == "System" ||
                conditionType === "slider" ||
                isSliderField
                  ? "number"
                  : "text"
              }
              value={value}
              className="form-control product-logic-input"
              style={{
                margin: "10px",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "18px",
                textAlign: "left",
                cursor: "pointer",
                height: "35px",
                border: "1px solid #d4d4d4",
              }}
              name="value"
              onChange={(e) =>
                handleStepLogic(
                  e,
                  selectedConditionPosition,
                  conditionIndex,
                  logicIndex
                )
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {isDateField ? (
        <>
          <div
            className="col-4"
            style={{
              margin: "10px",
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "18px",
              textAlign: "left",
              cursor: "pointer",
              height: "35px",
            }}
          >
            <DateTimePicker
              onChange={(e) => {
                const savedVal = e
                  ? moment(e).format("YYYY-MM-DD hh:mm:ss") || null
                  : null;

                handleStepLogic(
                  { target: { name: "value", value: savedVal } },
                  selectedConditionPosition,
                  conditionIndex,
                  logicIndex
                );
              }}
              value={value ? new Date(value) : null}
              disableClock={true}
              maxDate={new Date(9999, 12, 31)}
              format="yyyy/MM/dd HH:mm"
              className="product-logic-input"
              // disableCalendar={input.hideCalender}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {isSelectCotact ? (
        <>
          <div
            className="col-4"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "18px",
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            <ReactSelect
              isMulti={["one_of", "none_of", "all_of", "exact"].includes(
                operation
              )}
              placeholder={"Select..."}
              isSearchable={true}
              name="value"
              className="product-logic-input"
              value={
                Array.isArray(multiSelectValueContact)
                  ? selectedContact &&
                    selectedContact.selectOptions &&
                    selectedContact.selectOptions
                      .filter((option) =>
                        multiSelectValueContact.includes(option.name)
                      )
                      .map((option) => {
                        return {
                          label: option.name,
                          value: option.name,
                        };
                      })
                  : {
                      label: multiSelectValueContact,
                      value: multiSelectValueContact,
                    }
              }
              options={
                selectedContact &&
                selectedContact.selectOptions &&
                selectedContact.selectOptions.map(({ name }) => {
                  return {
                    label: name,
                    value: name,
                  };
                })
              }
              onChange={handleMultiSelectContactValue}
              styles={{
                control: (base) => ({
                  ...base,
                  marginRight: "-19px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }),
              }}
            />
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default withTranslation()(ConditionLine);
